<template>
  <b-card no-body class="card-custom mb-3">
    <b-alert
      :show="dismissCountDown"
      dismissible
      variant="success"
      @dismissed="dismissCountDown=0"
      @dismiss-count-down="countDownChanged"
    >
      Your changes were successfully saved!
    </b-alert>
    <b-tabs class="p-3" content-class="mt-3">
      <b-tab title="Customization" active>
        <b-tabs pills card vertical>
          <b-tab title="Defaults" active>
            <b-row>
              <b-col>
                <b-form-checkbox v-model="properties.showBox.value" :disabled="properties.showBox.disabled" name="showBox-button" switch>
                  {{ properties.showBox.value ? 'Opened' : 'Closed'}}
                </b-form-checkbox>
                <!--
                <b-form-checkbox v-model="properties.showClose.value" :disabled="properties.showClose.disabled" name="showClose-button" switch>
                  Show Close button
                </b-form-checkbox>
              -->

                <b-form-checkbox v-model="properties.sticky.value" :disabled="properties.sticky.disabled" name="showClose-button" @change="setSticky" switch>
                  Sticky
                </b-form-checkbox>
              </b-col>
              <b-col>
                <b-form-group v-if="properties.sticky.value" label="Delay appearance (in seconds)" >
                  <b-form-spinbutton v-model="properties.delay.value" :disabled="properties.delay.disabled" min="0"></b-form-spinbutton>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab v-if="properties.sticky.value" title="Positioning">
            <b-form-group label="Position">
              <b-form-select v-model="options.position.selected" :options="options.position.options" @change="toggleAdjustment()"></b-form-select>
            </b-form-group>

            <b-row>
              <b-col v-if="!options.positionAdjustment.top.disabled" lg="6" md="6" sm="12">
                <b-form-group label="Top (px)">
                  <b-form-spinbutton v-model="options.positionAdjustment.top.value" min="0"></b-form-spinbutton>
                </b-form-group>
              </b-col>
              <b-col v-if="!options.positionAdjustment.right.disabled" lg="6" md="6" sm="12">
                <b-form-group label="Right (px)">
                  <b-form-spinbutton v-model="options.positionAdjustment.right.value" min="0"></b-form-spinbutton>
                </b-form-group>
              </b-col>
              <b-col v-if="!options.positionAdjustment.bottom.disabled" lg="6" md="6" sm="12">
                <b-form-group label="Bottom (px)">
                  <b-form-spinbutton v-model="options.positionAdjustment.bottom.value" min="0"></b-form-spinbutton>
                </b-form-group>
              </b-col>
              <b-col v-if="!options.positionAdjustment.left.disabled" lg="6" md="6" sm="12">
                <b-form-group label="Left (px)">
                  <b-form-spinbutton v-model="options.positionAdjustment.left.value" min="0"></b-form-spinbutton>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>

      </b-tab>
      <b-tab title="Texts">
        <b-tabs pills card vertical >
          <b-tab title="English" active>
            <b-row>
              <b-col>
                <b-form-group label="Online Message 1">
                  <b-form-input v-model="texts.en.onlineMessage1" placeholder="enter text"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Online Message 2">
                  <b-form-input v-model="texts.en.onlineMessage2" placeholder="enter text"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <hr>
            <b-row>
              <b-col>
                <b-form-group label="Offline Message 1">
                  <b-form-input v-model="texts.en.offlineMessage1" placeholder="enter text"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Offline Message 2">
                  <b-form-input v-model="texts.en.offlineMessage2" placeholder="enter text"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <hr>
            <b-form-group label="Headline 1">
              <b-form-input v-model="texts.en.headline1" placeholder="enter text"></b-form-input>
            </b-form-group>

            <b-form-group label="Headline 2">
              <b-form-input v-model="texts.en.headline2" placeholder="enter text"></b-form-input>
            </b-form-group>
          </b-tab>
          <b-tab title="Deutsch">
            <b-row>
              <b-col>
                <b-form-group label="Online Message 1">
                  <b-form-input v-model="texts.de.onlineMessage1" placeholder="enter text"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Online Message 2">
                  <b-form-input v-model="texts.de.onlineMessage2" placeholder="enter text"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <hr>
            <b-row>
              <b-col>
                <b-form-group label="Offline Message 1">
                  <b-form-input v-model="texts.de.offlineMessage1" placeholder="enter text"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Offline Message 2">
                  <b-form-input v-model="texts.de.offlineMessage2" placeholder="enter text"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <hr>
            <b-form-group label="Headline 1">
              <b-form-input v-model="texts.de.headline1" placeholder="enter text"></b-form-input>
            </b-form-group>

            <b-form-group label="Headline 2">
              <b-form-input v-model="texts.de.headline2" placeholder="enter text"></b-form-input>
            </b-form-group>
          </b-tab>
        </b-tabs>
      </b-tab>
    </b-tabs>
    <b-card-text class="p-3 bg-light">
      <b-row>
        <b-col><b-button :disabled="isSaving" variant="primary" @click="save()">Update Settings</b-button></b-col>
        <b-col class="text-right"><div class="mt-2"><small>You can check your updates on your channel's origins.</small></div></b-col>
      </b-row>
    </b-card-text>
  </b-card>
</template>

<script>

export default {
  props: ['widget'],
  components: {
  },
  data(){
      return {
        isSaving: false,
        dismissSecs: 5,
        dismissCountDown: 0,
        showDismissibleAlert: false,
        properties: {
          showBox: {
            disabled: false,
            value: false
          },
          /*
          showClose: {
            disabled: false,
            value: true
          },
          */
          sticky: {
            disabled: false,
            value: true
          },
          delay: {
            value: 3,
            disabled: false
          },
        },
        texts: {
          en: {
            onlineMessage1: "Video consulting",
            onlineMessage2: "ONLINE",
            offlineMessage1: "Video consulting",
            offlineMessage2: "Set appointment",
            headline1: "Talk to a consultant",
            headline2: "Start a call via your browser to talk to one of our employees",
          },
          de: {
            onlineMessage1: "Videoberatung",
            onlineMessage2: "ONLINE",
            offlineMessage1: "Videoberatung",
            offlineMessage2: "Termin vereinbaren",
            headline1: "Sprechen Sie mit einem Berater!",
            headline2: "Starten Sie einen Anruf über Ihren Browser um mit einem unserer Mitarbeiter zu sprechen.",
          }
        },
        options: {
          position: {
            selected: 'bottom-right',
            options: [
              { text: 'Bottom Right', value: 'bottom-right', adjustment: ['bottom','right'] },
              { text: 'Bottom Left', value: 'bottom-left', adjustment: ['bottom','left'] },
              //{ text: 'Bottom Center', value: 'bottom-center', adjustment: ['bottom'] },
              { text: 'Top Left', value: 'top-left' , adjustment: ['top','left']},
              { text: 'Top Right', value: 'top-right', adjustment: ['top','right'] },
              //{ text: 'Top Center', value: 'top-center', adjustment: ['top']},
            ]
          },
          positionAdjustment: {
            top: {
              value: 0,
              disabled: true
            },
            right: {
              value: 0,
              disabled: true
            },
            bottom: {
              value: 0,
              disabled: true
            },
            left: {
              value: 0,
              disabled: true
            },
          },
        },
        widgetUrl: null,
      };
  },

  methods: {
    toggleAdjustment(){
      let pos = this.options.position.options.find(o => o.value === this.options.position.selected);
      if(pos){
        for(let name in this.options.positionAdjustment){
          this.options.positionAdjustment[name].disabled = pos.adjustment.indexOf(name) === -1;
        }
      }
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },
    disableProperties(disable){
      for(let key in this.properties){
        this.properties[key].disabled = disable;
      }
    },
    parsePositionAdjustment(){
      let pos = {};
      let options = this.options.position.options.find(o => o.value === this.options.position.selected);

      for(let i in options.adjustment){
        let name = options.adjustment[i];
        pos[name] = this.options.positionAdjustment[name].value;
      }

      return pos;
    },
    setSticky(value){
      if(value === true){
        this.options.position.selected = "bottom-right";
        this.toggleAdjustment();
      }
    },

    save(){
      this.isSaving = true;

      let custom = {
        showBox: this.properties.showBox.value,
        sticky: this.properties.sticky.value,
        texts: this.texts
      };

      if(this.properties.sticky.value === true){
        custom = Object.assign(custom,{
          delay: this.properties.delay.value,
          position: this.options.position.selected,
          positionAdjustment: this.parsePositionAdjustment(),
        })
      }

      this.$api.put(`widget/${this.widget.id}`,{data: {custom}})
      .then(() => {
        this.isSaving = false;
        this.showAlert()
      });
    },

    loadPreview(){
      let wp = this.$refs['widgetPreview'];
      wp.src = `${process.env.VUE_APP_EMBED_HOST}/preview/${this.widget.id}`+'?time='+Date.now();
      wp.onload = () => {
        this.disableProperties(false);
      };
    }
  },

  mounted(){
    this.$api.get(`widget/${this.widget.id}`).then(res => {
      let {custom} = res.data;
      if(custom){
        this.properties.showBox.value = custom.showBox;
        this.properties.delay.value = custom.delay === undefined ? 0 : custom.delay;
        this.properties.sticky.value = custom.sticky === undefined ? false : custom.sticky;
        this.options.position.selected = custom.position === undefined ? (custom.sticky === true ? "bottom-right" : null) : custom.position;
        if(custom.texts){
          this.texts = Object.assign({},custom.texts);
        }

        if(custom.sticky === true){
          this.options.positionAdjustment = {
            top: {
              value: (custom.positionAdjustment && custom.positionAdjustment.top ? custom.positionAdjustment.top : 0),
              disabled: true
            },
            right: {
              value: (custom.positionAdjustment && custom.positionAdjustment.right ? custom.positionAdjustment.right : 0),
              disabled: true
            },
            bottom: {
              value: (custom.positionAdjustment && custom.positionAdjustment.bottom ? custom.positionAdjustment.bottom : 0),
              disabled: true
            },
            left: {
              value: (custom.positionAdjustment && custom.positionAdjustment.left ? custom.positionAdjustment.left : 0),
              disabled: true
            },
          }

          this.toggleAdjustment();
        }
      }
    })
  },

  watch: {
    "properties.showBox.value": function(value){
      if(value === false && this.properties.sticky.value === false){
        this.properties.sticky.value = true;
      }
    },
    "properties.sticky.value": function(value){
      if(value === false && this.properties.showBox.value === false){
        this.properties.showBox.value = true;
      }
    },
  }
};
</script>
