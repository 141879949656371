<template>
  <div class="page widget-edit">
    <b-card no-body class="card-custom mb-3">
      <template #header>
        <b-button class="float-right" size="sm" variant="primary" v-b-modal.modal-show-code>
          <BIconCodeSlash/> Embed
        </b-button>
        <b-modal @show="createEmbedCode()" id="modal-show-code" title="Embed" size="lg">
            Copy and insert the following code on your website (as HTML):<br><br>
            <b-textarea id="sourcecodeValue" v-model="sourcecode"></b-textarea>
        </b-modal>

        <h6 class="mb-0 pt-1">Edit widget</h6>
        <small v-if="widget">ID: {{widget.id}}</small>
      </template>
      <b-card-text class="p-4">
        <WidgetForm v-if="widget" :widget="widget" :user="user"/>
        <div v-else>
          <b-spinner label="Loading..." small></b-spinner>
        </div>
      </b-card-text>
    </b-card>

    <WidgetCustomization v-if="widget" :widget="widget"/>



    <!--
    <b-card no-body :class="customBoxClass" v-if="widget">
      <template #header>
        <b-button v-if="screenSize == 'small'" @click="toggleMaximize()" class="float-right ml-3" size="sm" variant="light">
          <BIconArrowsAngleExpand style="font-size: 0.85rem;"/>
        </b-button>
        <b-button v-if="screenSize == 'big'" @click="toggleMaximize()" class="float-right ml-3" size="sm" variant="light">
          <BIconArrowsAngleContract style="font-size: 0.85rem;"/>
        </b-button>
        <h6 class="mb-0 pt-1">Customize</h6>
      </template>
      <b-card-text>
        <WidgetCustomization :widget="widget"/>
      </b-card-text>
    </b-card>
  -->
  </div>
</template>

<script>
import WidgetForm from './../../components/Widget/Widget.Form';
import WidgetCustomization from './../../components/Widget/Widget.Customization';

import {BIconCodeSlash} from 'bootstrap-vue';
export default {
    props: ['user'],
    components: {
      BIconCodeSlash,
      WidgetForm,WidgetCustomization
    },
    data(){
      return {
        widget: null,
        customBoxClass: "card-custom mb-3",
        screenSize: "small",
        sourcecode: null
      };
    },
    methods: {
      createHtmlCode(tag, attr, text){
          let div = document.createElement('div');
          let el = document.createElement(tag);
          for(let name in attr){
              el.setAttribute(name, attr[name]);
          }
          if(text){
              el.innerHTML = text;
          }
          div.appendChild(el);
          return div;
      },
      createEmbedCode(){
        this.sourcecode = this.createHtmlCode('script',{
            'id': this.widget.id,
            'src': `${process.env.VUE_APP_EMBED_HOST}/embed/${this.widget.id}`,
        }).innerHTML;
      },
      toggleMaximize(){
        switch(this.screenSize){
          case "small":
            this.customBoxClass = "card-custom card-custom-fullscreen";
            this.screenSize = 'big';
            break

          case "big":
            this.customBoxClass = "card-custom mb-3";
            this.screenSize = 'small';
            break
        }
      }
    },
    mounted(){
      this.$api.get(`widget/${this.$route.params.widgetId}`)
      .then((res) => {
        this.widget = res.data;
      })
      .catch(() => {
        console.log("cannot catch widget");
      });
    }
};
</script>
